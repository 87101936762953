
import CompanyPicker from '@/components/CompanyPicker/CompanyPicker.vue'
import FormInput from '@/components/FormInput/FormInput.vue'
import LabelPicker from '@/components/LabelPicker/LabelPicker.vue'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import UserPicker from '@/components/UserPicker/UserPicker.vue'
import Order from '@/models/Order'
import ViewModel from '@/models/ViewModel'
import {
  Component, Prop, Ref, Vue, Watch,
} from 'vue-property-decorator'
import { currencyMask, percentageMask } from '@/models/interface/Masks'
import WebMessage from '@/models/WebMessage'
import MediaPlanItem from '@/models/MediaPlanItem'
import InsertionOrderItemPicker from '@/components/InsertionOrderItemPicker/InsertionOrderItemPicker.vue'
import InsertionOrderPicker from '@/components/InsertionOrderPicker/InsertionOrderPicker.vue'
import DatePicker from '@/components/DatePicker/DatePicker.vue'

@Component({
  components: {
    CompanyPicker,
    FormInput,
    LabelPicker,
    SelectPicker,
    UserPicker,
    InsertionOrderPicker,
    InsertionOrderItemPicker,
    DatePicker,
  },
})
export default class OrderForm extends ViewModel {
  @Ref() validator!: any

  @Ref() trafficker!: any

  @Ref() submit_btn!: any

  @Prop() busy!: boolean

  @Prop() order!: Order

  @Prop() isEditing!: boolean

  public wait: boolean = true

  public local_value: Order | null = null

  public update: boolean = true

  public modal_open: boolean = false

  public loading: boolean = true

  public model_list: any = [
    //   CPM, CPC, CPCV
    {
      name: 'CPM',
      value: 'cpm',
    },
    {
      name: 'CPC',
      value: 'cpc',
    },
    {
      name: 'CPCV',
      value: 'cpcv',
    },
  ]

  public buffer_rules: any = [
    {
      name: 'None',
      value: 'none',
    },
    {
      name: 'Fixed',
      value: 'fixed',
    },
    {
      name: 'Percentage',
      value: 'percentage',
    },
  ]

  public order_action_options: any = [
    {
      name: 'Create new',
      value: 'create',
    },
    {
      name: 'Use Existing Order',
      value: 'existing',
    },
  ]

  @Watch('modal_open')
  public onModalOpenChange(value: boolean) {
    this.$emit('modal_open', value)
  }

  public get masks() {
    return { percentageMask, currencyMask }
  }

  created() {}

  mounted() {
    setTimeout(() => {
      Vue.set(this, 'local_value', this.order)

      this.loadLineItem()

      if (this.local_value && !this.local_value.id) {
        Vue.set(this.local_value, 'trafficker_id', this.user.id)
      }
      this.loading = false
    }, 800)
  }

  public loadLineItem() {
    if (this.$route.query.media_plan_item) {
      // @ts-ignore
      MediaPlanItem.find(this.$route.query.media_plan_item).then(res => {
        let order = Object.assign(new Order(), {
          agency_id: res.media_plan.agency_id,
          station_id: res.media_plan.station_id,
          advertiser_id: res.media_plan.advertiser_id,
          media_plan_id: res.media_plan.id,
          media_plan_item_id: res.id,
          contracted_amount: res.impressions,
          trafficker_id: this.user.id,
          name: res.name,
          buffer_percentage: 0.1,
          archived: false,
          metrics: {},
        })
        Vue.set(this, 'local_value', order)
        this.wait = false
      })
    } else {
      this.wait = false
    }
  }

  public submitForm() {
    this.submit_btn.click()
  }

  public onSubmit() {
    this.loading = true
    this.local_value.save().then(response => {
      if (response.status === 200) {
        this.$emit('saved', { id: response.data.result.order.id })
      }
      this.loading = false
    })
  }

  public confirmOpenEnded() {
    if (!this.order.open_ended) return
    WebMessage.confirm(
      'This action will allow the order to deliver unlimited impressions until the end of the campaign, please confirm that this is the intended behaviour.',
      'Are you sure you want to set this order to open ended?',
      {
        okTitle: 'Yes, This Order should be open ended',
        cancelText: 'No',
      },
    ).then((res: any) => {
      if (!res) {
        this.order.open_ended = false
      }
    })
  }
}
