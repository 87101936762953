
import FormInput from '@/components/FormInput/FormInput.vue'
import ViewModel from '@/models/ViewModel'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {
    FormInput,
  },
})
export default class IpTargetting extends ViewModel {
  @Prop()
  value!: any

  public local_value: any = []

  public include_ip: boolean = true

  public temp_ip: string = ''

  public loading: boolean = true

  public mounted() {
    this.loadUsedIps()
    setTimeout(() => {
      this.loading = false
    }, 1000)
  }

  public loadUsedIps() {
    this.value.include_ip.forEach((ip: any) => {
      this.local_value.push({
        type: 'ip',
        action: 'include',
        value: ip,
        name: ip,
      })
    })

    this.value.exclude_ip.forEach((ip: any) => {
      this.local_value.push({
        type: 'ip',
        action: 'exclude',
        value: ip,
        name: ip,
      })
    })
  }

  public validateIPaddress(ipaddress: any, callback: any) {
    if (ipaddress.length) callback(false)
    if (
      /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
        ipaddress,
      )
    ) {
      callback(true)
    }
    callback(false)
  }

  public addIP() {
    let ips = this.temp_ip.split(/[\n,;]/)
    if (ips.length > 0) {
      ips.forEach((ip: any) => {
        this.validateIPaddress(ip.trim(), (res: boolean) => {
          if (res) {
            let index = this.local_value.findIndex((n: any) => n.value === ip.trim())

            if (index > -1) {
              this.local_value[index].action = this.include_ip ? 'include' : 'exclude'
            } else if (ip.trim().length) {
              this.local_value.push({
                type: 'ip',
                action: this.include_ip ? 'include' : 'exclude',
                value: ip.trim(),
                name: ip.trim(),
              })
            }
          }
        })
      })
    }
    this.temp_ip = ''

    this.emit()
  }

  public remove(index: number) {
    this.local_value.splice(index, 1)
    this.emit()
  }

  public emit() {
    setTimeout(() => {
      let include = this.local_value.filter(sn => sn.action === 'include')
      let exclude = this.local_value.filter(sn => sn.action === 'exclude')

      const map_values = (arr: any) => arr.map((n: any) => n.value)

      this.$emit('input', {
        ...this.value,
        include_ip: map_values(include),
        exclude_ip: map_values(exclude),
      })
    }, 400)
  }
}
