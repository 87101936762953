
import LineItem from '@/models/LineItem'
import ViewModel from '@/models/ViewModel'
import {
  Component, Prop, Ref, Vue, Watch,
} from 'vue-property-decorator'
import EventTracker from '@/components/EventTracker/EventTracker.vue'
import Order from '@/models/Order'
import WebMessage from '@/models/WebMessage'
import { percentageMask, currencyMask } from '@/models/interface/Masks'
import Widget from '@/components/Widget/Widget.vue'
import FormInput from '@/components/FormInput/FormInput.vue'
import ColorManager from '@/models/interface/ColorManager'
import OrderPicker from '@/components/OrderPicker/OrderPicker.vue'
import CompanyPicker from '@/components/CompanyPicker/CompanyPicker.vue'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import DatePicker from '@/components/DatePicker/DatePicker.vue'
import InsertionOrderPicker from '@/components/InsertionOrderPicker/InsertionOrderPicker.vue'
import InsertionOrderItemPicker from '@/components/InsertionOrderItemPicker/InsertionOrderItemPicker.vue'
import DistributionGoalGroup from '@/pages/Sales/Packages/components/DistributionGoalGroup.vue'
import IconAction from '@/components/IconAction/IconAction.vue'
import moment from 'moment'
import CheckboxInput from '@/components/CheckboxInput/index.vue'
import MediaPackagePicker from '@/components/MediaPackagePicker/MediaPackagePicker.vue'
import MediaPackage from '@/models/MediaPackage'
import VueSlider from 'vue-slider-component'
import InspectTargetAll from '@/components/InspectTarget/all.vue'
import InspectTarget from '@/components/InspectTarget/index.vue'
import MediaPlanItem from '@/models/MediaPlanItem'
import lineitem_options from '../line-items-options'
import TimeRestriction from './TimeRestriction.vue'
import DeviceCategory from './Targetting/DeviceCategory.vue'
import CustomTargetting from './Targetting/CustomTargetting.vue'
import InventoryTargetting from './Targetting/InventoryTargetting.vue'
import GeoTargetting from './Targetting/GeoTargetting.vue'
import IpTargetting from './Targetting/IpTargetting.vue'
import 'vue-slider-component/theme/antd.css'

type collapseStateKey =
  | 'device_targetting'
  | 'custom_targetting'
  | 'inventory_targetting'
  | 'geo_targetting'
  | 'ip_targetting'

@Component({
  components: {
    EventTracker,
    Widget,
    FormInput,
    OrderPicker,
    CompanyPicker,
    SelectPicker,
    DatePicker,
    TimeRestriction,
    DeviceCategory,
    CustomTargetting,
    InventoryTargetting,
    GeoTargetting,
    InsertionOrderPicker,
    InsertionOrderItemPicker,
    DistributionGoalGroup,
    IconAction,
    IpTargetting,
    CheckboxInput,
    MediaPackagePicker,
    VueSlider,
    InspectTarget,
    InspectTargetAll,
  },
})
export default class LineItemForm extends ViewModel {
  @Ref() readonly timeRestrictionRef: any

  @Ref() readonly geoTargetAllRefInfo: any

  @Ref() readonly inventoryTargetAllRefInfo: any

  @Prop()
  public value!: any

  @Prop()
  public hideInputs!: any

  @Ref() readonly eventTracker!: EventTracker

  public order_line_item: LineItem = new LineItem()

  public slider = {
    marks: (val: number) => ({
      label: `${val}x`,
    }),
    process: (dotpos: number[]) => {
      let ret = []
      for (let i = 0; i < dotpos.length; i++) {
        ret.push([i === 0 ? 0 : dotpos[i - 1], dotpos[i], { backgroundColor: '#3abf94' }])
      }
      return ret
    },
  }

  public busy: boolean = true

  public time_restriction_reload: boolean = false

  public wait: boolean = false

  public update: boolean = false

  public loading: boolean = true

  public collapse_event_list: any = {
    geo_targetting: false,
    inventory_targetting: false,
  }

  public line_item_types: any = [
    { text: 'Sponsorship (4)', value: 'sponsorship' },
    { text: 'Standard (6,8,10)', value: 'standard' },
    { text: 'Backfill (12, 13, 14)', value: 'backfill' },
  ]

  public geoTargettingLoaded: boolean = false

  public inventoryTargettingLoaded: boolean = false

  public targettingCollapseStates: any = {
    device_targetting: {
      loaded: false,
      toggle: false,
    },
    custom_targetting: {
      loaded: false,
      toggle: false,
    },
    inventory_targetting: {
      loaded: false,
      toggle: false,
    },
    geo_targetting: {
      loaded: false,
      toggle: false,
      status: false,
    },
    ip_targetting: {
      loaded: false,
      toggle: false,
    },
  }

  @Watch('order_line_item', { deep: true })
  public onOrderLineItemChange(val: any) {
    if (val && this.update) {
      this.$emit('input', val)
    }
  }

  @Watch('order_line_item.media_package_id')
  public onMediaPackageChange(val: any) {
    if (
      val
      && val.length
      && !this.busy
      && (this.order_line_item.targetting.include_adunits.length
        || this.order_line_item.targetting.exclude_adunits.length)
    ) {
      this.confirmMediaPackageChange(val)
    }
  }

  public get macros() {
    return this.eventTracker?.macros
  }

  public get line_item_options() {
    return lineitem_options
  }

  public get masks() {
    return { percentageMask, currencyMask }
  }

  public mounted() {
    this.reload()

    if (this.$route.params?.id && this.$route.name !== 'mediaplan-item') {
      // @ts-ignore
      LineItem.find(this.$route.params?.id).then((response: LineItem) => {
        this.order_line_item = response

        if (!response.targetting.target_time) {
          this.order_line_item.targetting.target_time = 'system'
        }
        if (!response.targetting.exclude_adunits) {
          this.order_line_item.targetting.exclude_adunits = []
        }
        if (!response.targetting.include_adunits) {
          this.order_line_item.targetting.include_adunits = []
        }

        if (this.$route.params.clone) {
          this.order_line_item.id = null
          this.order_line_item.delivery_boost = 0
          this.order_line_item.remote_asap = false
          this.order_line_item.no_pod_targeting = false
          this.order_line_item.remote_buffer_percentage = 0
        }

        setTimeout(() => {
          this.busy = false

          // check if cloning
          if (this.$route.query && this.$route.query.cloning && !this.order_line_item.cloned) {
            const { name } = this.order_line_item
            this.order_line_item.name = `[${moment().format('MMM, Do YYYY HH:mm')} Copy]: ${name}`

            let associated_creatives = []
            for (const key in this.order_line_item.associated_creatives) {
              if (this.order_line_item.associated_creatives[key].creative.enabled) {
                associated_creatives.push(this.order_line_item.associated_creatives[key])
              }
            }

            this.order_line_item.associated_creatives = associated_creatives
          }

          this.loading = false
        }, 1000)
      })
    } else if (this.$route.query?.order_id) {
      this.setDataFromOrder(this.$route.query?.order_id)
    } else {
      setTimeout(() => {
        this.busy = false
        this.loading = false
      }, 1000)
    }
  }

  public reload() {
    this.wait = true
    this.update = false
    if (this.value) {
      Vue.set(this, 'order_line_item', Object.assign(this.order_line_item, this.value))
    } else {
      this.order_line_item = new LineItem()
    }

    setTimeout(() => {
      this.update = true
      // this.timeRestrictionRef?.reload()
      this.time_restriction_reload = true
      this.wait = false
      this.time_restriction_reload = false
    }, 1000)
  }

  public shouldHide(name: any) {
    if (this.hideInputs && this.hideInputs.length) {
      return !this.hideInputs.includes(name)
    }
    return true
  }

  public model_info(): any {
    return {
      cpm: {
        label: 'Booked Impressions',
        name: 'impressions',
      },
      cpc: {
        label: 'Clicks',
        name: 'clicks',
      },
      cpcv: {
        label: 'Completed Views',
        name: 'completed views',
      },
    }
  }

  public addFrequency() {
    this.order_line_item.frequency_caps.push({
      id: this.randomUUID(),
      impressions: 1,
      limit: 1,
      period: 'week',
    })
  }

  public removeFrequency(index: any) {
    this.order_line_item.frequency_caps.splice(index, 1)
  }

  @Watch('order_line_item.order_id')
  public onOrderChange(val: any) {
    if (val) {
      this.setDataFromOrder(val)
    }
  }

  public setDataFromOrder(order: string) {
    if (!order || this.order_line_item.id || this.$route.params.clone) return

    Order.find(order).then((order: Order) => {
      if (order.id != this.order_line_item.order_id && this.order_line_item.order_id) {
        return
      }
      this.order_line_item.start_at = order.start_at
        ? moment(order.start_at).startOf('day').format('YYYY-MM-DD HH:mm:ss')
        : ''
      this.order_line_item.end_at = order.end_at
        ? moment(order.end_at).endOf('day').format('YYYY-MM-DD HH:mm:ss')
        : ''
      this.order_line_item.agency_id = order.agency_id
      this.order_line_item.station_id = order.station_id
      this.order_line_item.advertiser_id = order.advertiser_id
      this.order_line_item.media_plan_id = order.media_plan_id
      this.order_line_item.media_plan_item_id = order.media_plan_item_id
      if (!this.order_line_item.order_id) {
        this.order_line_item.order_id = order.id
      }

      this.order_line_item.order = order
      this.$emit('order', order)
      setTimeout(() => {
        this.busy = false
        this.loading = false
      }, 1000)
    })
  }

  public selectLineItem(name: string) {
    let rule = this.line_item_options.line_item_types.find((item: any) => item.value === name)
    if (rule) {
      this.order_line_item.line_item = Object.assign(this.order_line_item.line_item, {
        ...rule,
      })
    }
  }

  public confirmOpenEnded() {
    if (!this.order_line_item.open_ended) return
    WebMessage.confirm(
      'This action will allow the line item to deliver unlimited impressions until the end of the campaign, please confirm that this is the intended behaviour.',
      'Are you sure you want to set this line item to open ended?',
      {
        okTitle: 'Yes, This Line Item should be open ended',
        cancelText: 'No',
      },
    ).then((res: any) => {
      if (!res) {
        this.order_line_item.open_ended = false
      }
    })
  }

  public setErrors(e: any) {
    // @ts-ignore
    this.$refs.validator?.setErrors(e)
  }

  public onSubmit() {
    if (
      this.order_line_item.remote_buffer_percentage > 0
      && this.order_line_item.buffer_rule !== 'percentage'
    ) {
      WebMessage.error(
        'You cannot set a remote buffer percentage without setting a buffer rule to percentage',
      )
      return
    }

    this.loading = true
    this.order_line_item.save().then((res: any) => {
      if (res?.status === 200) {
        this.$emit('saved', res.data.result)
      }
      this.loading = false
    })
  }

  public buffer_rule(input: string) {
    let rule = this.order_line_item.buffer_rule

    if (rule === 'percentage') {
      this.order_line_item.buffer_value = 0
    } else if (rule === 'fixed') {
      this.order_line_item.buffer_percentage = 0
    } else {
      this.order_line_item.buffer_value = 0
      this.order_line_item.buffer_percentage = 0
    }
    if (input === 'buffer_percentage' && rule === 'percentage') return 'required|min_value:1'
    if (input === 'buffer_value' && rule === 'fixed') return 'required|min_value:1'
    return ''
  }

  public toggleGeoTargetting(val: boolean) {
    if (!this.geoTargettingLoaded) {
      this.geoTargettingLoaded = true
    }
  }

  public toggleInventoryTargetting(val: boolean) {
    if (!this.inventoryTargettingLoaded) {
      this.inventoryTargettingLoaded = true
    }
  }

  public toggleCollapse(key: collapseStateKey, timeout: number = 100) {
    let { loaded, toggle } = this.targettingCollapseStates[key]

    if (!loaded) {
      setTimeout(() => {
        this.targettingCollapseStates[key].loaded = true
      }, timeout)
    }
  }

  public confirmMediaPackageChange(id: string) {
    WebMessage.doubleConfirm(
      'Changing the media package will remove the current inventory targetting to the selected media package inventory, are you sure you want to continue?',
      'Are you sure you want to change the media package?',
      'Yes, Change Media Package',
      {
        okTitle: 'Confirm',
        cancelText: 'Cancel',
      },
    ).then((res: any) => {
      if (res) {
        this.targettingCollapseStates.inventory_targetting.toggle = false
        this.targettingCollapseStates.inventory_targetting.loaded = false

        MediaPackage.find(id).then((media_package: MediaPackage) => {
          Vue.set(
            this.order_line_item.targetting,
            'include_adunits',
            media_package.adunit_ids || [],
          )
          Vue.set(
            this.order_line_item.targetting,
            'exclude_adunits',
            media_package.exclude_adunit_ids || [],
          )

          Vue.set(
            this.order_line_item,
            'distribution_goals',
            media_package.distribution_goals || [],
          )

          this.targettingCollapseStates.inventory_targetting.toggle = true
        })
      }
    })
  }

  public loadMediaPlanItemObj(e: any) {
    if (this.loading) return
    if (this.$route.params.id && this.order_line_item.media_plan_item.id == e.value) {
      return
    }
    this.loading = true
    if (e.hasOwnProperty('value')) {
      const load = () => {
        MediaPlanItem.find(e.value)
          .then(response => {
            this.order_line_item.media_plan_item = response
            this.loading = true
          })
          .catch(() => {
            this.loading = false
          })
      }

      if (!this.value.media_plan_item.id || !this.value.media_plan_item.id.length) {
        load()
      } else if (this.order_line_item.media_plan_item.id !== e.value) {
        load()
      }
    }
  }
}
