
import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import ViewModel from '@/models/ViewModel'
import Label from '@/models/Label'
import SelectOption from '@/models/interface/SelectOption'
import FormInput from '../FormInput/FormInput.vue'
import LabelSideBar from './LabelSideBar.vue'

@Component({
  components: {
    SelectPicker,
    FormInput,
    LabelSideBar,
  },
})
export default class LabelPicker extends ViewModel {
  public temp_label: Label = new Label()

  @Prop({ default: true })
  public edit!: boolean

  @Prop({ default: false })
  public allow_empty!: boolean

  @Prop({ default: false })
  public disabled!: boolean

  @Prop({ default: false })
  public multiple!: boolean

  @Prop({ default: '' })
  public company_id!: string

  @Prop({ default: '' })
  public station_id!: string

  @Prop({ default: false })
  public create_panel!: boolean

  @Prop({ default: 'Labels' })
  public label!: string

  @Prop({ default: () => null })
  public value!: string | null

  public local_value: string[] | string | null = []

  public new_id: string = ''

  public show_sidebar: boolean = false

  @Watch('show_sidebar')
  public onShowSidebar(val: boolean) {
    this.$emit('update:show_sidebar', val)
  }

  public update: boolean = true

  public uuid: string = ''

  public busy: boolean = false

  public options: SelectOption[] = []

  public label_name: string = ''

  public ready: boolean = false

  @Prop({ default: null })
  public type!: string | null

  public mounted() {
    this.uuid = this.randomUUID()
  }

  public created() {
    if (this.edit) {
      if (!this.value || (Array.isArray(this.value) && this.value.length == 0)) {
        this.ready = true
      } else {
        this.busy = true
        Label.searchOptions({
          value: this.value,
        }).then(response => {
          this.options = response
          this.busy = false
          this.local_value = this.value
          this.ready = true
        })
      }
    } else {
      this.updateLabel()
    }
  }

  public open() {
    if (this.options.length == 0) this.searchOptions()
  }

  public updateLabel() {
    if (!this.value) {
      this.label_name = 'N/A'
    } else if (!Array.isArray(this.value)) {
      Label.searchOptions({
        value: this.value,
      }).then(response => {
        this.label_name = response[0].name
        this.ready = true
      })
    }
  }

  public searchOptions(search = '*') {
    this.busy = true
    if (!search) search = '*'

    Label.searchOptions({
      search: search.includes('*') ? search : `*${search}*`,
      type: this.type,
    }).then(response => {
      this.options = response
      this.busy = false
      this.local_value = this.value
      this.ready = true
    })
  }

  public get can_create() {
    return this.create_panel && this.user.can('profile', 'edit')
  }

  @Watch('local_value')
  public onChangeValue(val: any) {
    this.update = false
    if (val == null) {
      this.$emit('input', null)
    } else {
      this.$emit('input', this.local_value)
    }
  }

  @Watch('value')
  public onChangeProp(val: any) {
    if (this.update) {
      if (val == null) {
        this.local_value = null
      } else {
        this.local_value = this.value
      }
    }
    this.update = true
    this.updateLabel()
  }

  public saveLabel() {
    this.label.save().then(response => {})
  }
}
