
import {
  Component, Prop, Ref, Vue, Watch,
} from 'vue-property-decorator'
import LineItem from '@/models/LineItem'
import Widget from '@/components/Widget/Widget.vue'
import ViewModel from '@/models/ViewModel'
import moment from 'moment'
import EditableSlot from '@/pages/LineItem/components/EditableSlot.vue'
import AssociatedCreative from '@/models/AssociatedCreative'
import IconAction from '../IconAction/IconAction.vue'
import FormInput from '../FormInput/FormInput.vue'
import DatePicker from '../DatePicker/DatePicker.vue'
import SelectPicker from '../SelectPicker/SelectPicker.vue'

@Component({
  components: {
    Widget,
    IconAction,
    FormInput,
    EditableSlot,
    DatePicker,
    SelectPicker,
  },
})
export default class CreativeSimpleTable extends ViewModel {
  @Ref() localTabel!: any

  @Prop()
  public value!: AssociatedCreative[]

  public fields: any = [
    {
      key: 'checkbox',
      label: '',
      sortable: false,
      class: 'text-center align-middle',
      thClass: 'align-middle text-center',
      show: true,
      type: 'checkbox',
      thStyle: { width: '3rem' },
    },
    {
      key: 'name',
      label: 'Name',
      sortable: true,
      show: true,
      class: 'text-left align-middle text-capitalize',
    },
    {
      key: 'weight_mode',
      label: 'Weight Mode',
      sortable: true,
      class: 'text-center align-middle text-capitalize',
      show: true,
      thStyle: { width: '20rem' },
    },
    {
      key: 'type',
      label: 'Type',
      sortable: true,
      class: 'text-center align-middle ',
      show: false,
    },
    {
      key: 'sequence',
      label: 'Sequence',
      sortable: true,
      class: 'text-center align-middle fixed-width-sm',
      show: false,
      thStyle: { width: '10rem' },
    },
    {
      key: 'weight_value',
      label: 'Weight Value',
      sortable: true,
      class: 'text-center align-middle ',
      show: false,
      thStyle: { width: '15rem' },
    },
    {
      key: 'start_at',
      label: 'Start At',
      sortable: true,
      class: 'text-center align-middle ',
      show: true,
    },

    {
      key: 'end_at',
      label: 'End At',
      sortable: true,
      class: 'text-center align-middle ',
      show: true,
    },

    {
      key: 'duration',
      label: 'Duration',
      sortable: true,
      class: 'text-center align-middle ',
      show: true,
    },
    {
      key: 'ready',
      label: 'Status',
      sortable: true,
      class: 'text-center align-middle ',
      show: true,
    },
    {
      key: 'created_at',
      label: 'Created At',
      sortable: true,
      class: 'text-center align-middle ',
      show: true,
    },
    {
      key: 'updated_at',
      label: 'Update At',
      sortable: true,
      class: 'text-center align-middle ',
      show: false,
    },
    {
      key: 'action',
      label: 'Action',
      class: 'text-center align-middle ',
      sortable: false,
      show: true,
    },
  ]

  @Prop()
  public lineItemIds!: any

  public loading: boolean = true

  public weight_modes: any = [
    {
      value: 'auto',
      name: 'Auto',
    },
    {
      value: 'goal',
      name: 'Goal',
    },
    {
      value: 'percentage',
      name: 'Percentage',
    },
  ]

  public selected_options: any = []

  public all_selected: boolean = false

  @Watch('all_selected')
  public onAllSelectedChanged(val: any) {
    if (val) {
      this.selected_options = this.local_value.map((c: any) => c.id)
    } else {
      this.selected_options = []
    }
  }

  public get local_value() {
    return this.value
  }

  public set local_value(value: any) {
    this.$emit('input', value)
  }

  @Watch('lineItemIds')
  public onLineItemIdsChanged(val: any) {
    this.loadCreatives(val)

    setTimeout(() => {
      // this is to force the search, on the backend there is a query for unique creative_id
      // so if you select two line items that has the same creative, it will only return creatives from one of the line items
      // this is because of the filter in the loadCreatives
      this.loadCreatives(val)
    }, 1000)
  }

  public get indeterminate(): boolean {
    return (
      this.selected_options.length > 0 && this.selected_options.length < this.local_value.length
    )
  }

  public get show_fields() {
    return this.fields.filter(field => field.show)
  }

  public get startOfDay() {
    return moment().startOf('day').toDate()
  }

  public get endOfDay() {
    return moment().endOf('day').toDate()
  }

  public async loadCreatives(e) {
    if (!e.length) return
    this.loading = true
    LineItem.loadCreativesFromLineItems(e).then(data => {
      const temp_creatives = data.map(d => ({
        ...d,
        weight_mode: 'auto',
        weight_value: 0,
        sequence: 0,
        status: 'not saved',
      }))

      // only add of the creatives that are not already in the list
      const newCreatives = temp_creatives
        .filter((c: any) => !this.local_value.some((c2: any) => c2.creative_id === c.creative_id))
        .filter((c: any) => !this.local_value.some((c2: any) => c2.id === c.id)) // delete the id from creative, this is done on the save method from parent component page

      this.local_value = this.local_value
        .concat(newCreatives)
        .filter((cre: any) => e.includes(cre.owner_id) || cre.not_saved || cre.direct)

      this.loading = false
      this.refresh()
    })
  }

  public requiredDateIfSelected(index: number, item: any, property: string) {
    if (property === 'end_at') property = 'start_at'
    else property = 'end_at'
    // @ts-ignore
    let component = this.$refs[`${property}_${index}`]

    if (component && !item[property]) {
      // @ts-ignore
      component.$refs[`date_picker_${component.uuid}`].$refs.input.focus()
    }
  }

  public refresh() {
    this.localTabel.refresh()
  }

  public widgetRefresh() {
    this.loadCreatives(this.lineItemIds)
  }

  public previewCreative(creative: any) {
    this.$emit('preview-creative', creative.creative)
  }

  public removeCreative(creative: any) {
    this.local_value = this.local_value.filter((c: any) => c.creative_id !== creative.creative_id)
  }

  public removeselected() {
    this.local_value = this.local_value.filter((c: any) => !this.selected_options.includes(c.id))
  }
}
