
import Label from '@/models/Label'
import ViewModel from '@/models/ViewModel'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import FormInput from '../FormInput/FormInput.vue'

@Component({
  components: {
    FormInput,
  },
})
export default class LabelForm extends ViewModel {
  public temp_label: Label = new Label()

  public loading: boolean = false

  public view_mode: string = 'sidebar'

  @Prop()
  public new_id!: any

  public saveLabel() {
    this.loading = true

    this.temp_label.save().then(response => {
      if (response.status == 200) {
        if (this.view_mode == 'sidebar') {
          // console.log('its a side bar', response.data.result.label.id)
          this.$emit('update:new_id', response.data.result.label.id)
          this.$emit('update:show_sidebar', false)
        }
      }

      this.$emit('saved')

      this.loading = false
    })
  }
}
