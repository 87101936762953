import Model from './interface/Model'
import Api from './Api'
import SelectOption from './interface/SelectOption'
import WebMessage from './WebMessage'

export default class Label extends Model {
  public api = new Api()

  protected api_settings = {
    save_mode: 'form',
    paths: {
      singular: 'labels' as string | null,
      plural: 'labels' as string | null,
    },
  }

  public id: string | null = null

  public _name: any = ''

  public value: any = null

  public description: any = ''

  public set name(val: any) {
    this._name = val
    this.value = val.replaceAll(' ', '_').toLowerCase() || null
  }

  public get name(): any {
    return this._name
  }

  public get apiData() {
    return {
      id: this.id,
      name: this.name,
      value: this.value,
      description: this.description,
    }
  }

  protected static onSave(response: any) {
    return response
  }

  private static onError(error: any) {
    return error
  }
}
