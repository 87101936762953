
import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator'
import ViewModel from '@/models/ViewModel'
import Inventory from '@/models/Inventory'

@Component
export default class InspectTarget extends ViewModel {
  @Prop({ default: false })
  public hasIncludeExclude!: boolean

  @Prop({ default: false })
  public componentMode!: boolean

  @Prop()
  public target!: string

  @Prop()
  public value!: any

  @Prop({ default: 3 })
  public displayQuantity!: number

  public loading: boolean = false

  public selected_options: any = []

  @Watch('value')
  public onValueChange(val: any) {
    if (!this.hasIncludeExclude) {
      if (val && val.length) {
        if (!this.loading) {
          setTimeout(() => {
            this.loadTargetData(this.target)
          }, 100)
        }
      }
    } else if ((val.include && val.include.length) || (val.exclude && val.exclude.length)) {
      if (!this.loading) {
        setTimeout(() => {
          this.loadTargetData(this.target)
        }, 100)
      }
    } else {
      this.selected_options = []
    }
  }

  // get remaining options to display on tooltip filter out filterOut(this.selected_options)
  public get remaining_options() {
    if (!this.selected_options || !this.selected_options.length) return []

    // return from selected_options array index > displayQuantity
    return this.selected_options.slice(this.displayQuantity)
  }

  public get selected_option_list() {
    if (!this.selected_options || !this.selected_options.length) return []

    this.selected_options.slice(0, this.displayQuantity)
    return this.selected_options
      .map((op: any) => op.name)
      .toString()
      .replace(/,/g, ', ')
  }

  public get selected() {
    if (this.hasIncludeExclude) {
      let total = 0
      if (this.value.include && this.value.include.length) {
        total = this.value.include.length
      }
      if (this.value.exclude && this.value.exclude.length) {
        total += this.value.exclude.length
      }
      return total
    }
    if (!this.value) return 0
    return this.value.length
  }

  public filterOut(arr: any[]) {
    let cutted = arr.filter((_, index) => index < this.displayQuantity - 1)
    return cutted.sort((a, b) => {
      if (a.name < b.name) {
        return -1
      }
      if (a.name > b.name) {
        return 1
      }
      return 0
    })
  }

  public mounted() {
    this.loadTargetData(this.target)
  }

  public loadTargetData(target: string) {
    this.loading = true
    const targets: any = {
      inventory: () => this.loadInventory(),
    }
    targets[target]()
      .then(() => {
        this.loading = false
      })
      .catch(() => {
        this.loading = false
      })
  }

  public async loadInventory() {
    let values = this.value

    if (this.hasIncludeExclude) {
      values = [...values.include, ...values.exclude]
    }

    return Inventory.loadInventories({
      selected_inventory_ids: values,
    }).then((response: any) => {
      this.selected_options = response.selected_inventories
      return response
    })
  }
}
