
import {
  Component, Prop, Ref, Vue, Watch,
} from 'vue-property-decorator'
import ViewModel from '@/models/ViewModel'
import CreativeForm from '@/pages/Creative/components/CreativeForm.vue'
import Creative from '@/models/Creative'
import AssociatedCreative from '@/models/AssociatedCreative'
import { clone } from 'lodash'
import SelectPicker from '../SelectPicker/SelectPicker.vue'
import CreativePicker from '../CreativePicker/CreativePicker.vue'

@Component({
  components: {
    CreativePicker,
    SelectPicker,
    CreativeForm,
  },
})
export default class CreativeModalCreator extends ViewModel {
  @Ref() creative_form!: CreativeForm

  @Prop()
  public value!: any

  @Prop({ default: true })
  public toggleCrossCreative!: any

  @Prop({ default: 'line_item' })
  public associatedCreativesLocation!: any

  @Prop()
  public LineItem!: any

  @Prop()
  public order!: any

  @Prop({ default: false })
  public modal!: any

  @Prop({ default: false })
  public creative_sidebar!: any

  public get local_modal() {
    return this.modal
  }

  public set local_modal(value: any) {
    this.$emit('update:modal', value)
  }

  @Watch('local_modal')
  public onLocalModalChange(val: boolean) {
    if (!val) this.selected_action = null
  }

  public get local_creative_sidebar() {
    return this.creative_sidebar
  }

  public set local_creative_sidebar(value: any) {
    this.$emit('update:creative_sidebar', value)
  }

  public get local_value() {
    return this.value
  }

  public set local_value(value: any) {
    this.$emit('input', value)
  }

  public searchableAssociatedCreatives() {
    let location = this.associatedCreativesLocation
    if (location) {
      if (location === 'order') {
        return this.order.associated_creatives
      }
      if (location === 'line_item') {
        return this.LineItem.order.associated_creatives
      }
    }
  }

  public loading_creative: boolean = false

  public edit_creative: boolean = false

  public selected_action: string | null = null

  public temp_creative_collection: any[] = []

  public cross_advertiser: boolean = false

  public temp_creative: any = {
    weight_mode: 'auto',
    weight_value: 0,
    sequence: 0,
    creative: {
      id: null,
      name: '-',
    },
    start_at: null,
    end_at: null,
    selected_option: null,
    status: 'not saved',
  }

  public cancelNewCreative() {
    this.temp_creative = {
      weight_mode: 'auto',
      weight_value: 0,
      sequence: 0,
      creative: {
        id: null,
        name: '-',
      },
      start_at: null,
      end_at: null,
      selected_option: null,
      status: 'not saved',
    }
    this.local_modal = false
  }

  public get line_item_adserver() {
    if (!this.LineItem) return false
    return this.LineItem.adserver
  }

  public addCreativeToLineItem() {
    // search in backend for ids

    Creative.getCreativesByIds(this.temp_creative_collection).then((data: any) => {
      let creatives: any = data.map((cre: any) => ({
        weight_mode: 'auto',
        weight_value: 0,
        sequence: 0,
        creative: cre,
        creative_id: cre.id,
        start_at: null,
        end_at: null,
        selected_option: null,
        status: 'not saved',
        direct: true,
      }))

      creatives = creatives.filter(c => !this.local_value.some(lv => lv.creative.id === c.creative.id))

      this.local_value = this.local_value.concat(creatives)

      this.temp_creative_collection = []

      this.local_modal = false
    })
  }

  public mapToOptions(associated_creatives: any) {
    return associated_creatives.map((ac: any) => ({
      name: ac.creative.name,
      value: ac.id,
    }))
  }

  public savedCreative(creative: any) {
    this.temp_creative.creative = creative

    if (this.edit_creative) {
      let creative_index = this.local_value.findIndex(
        (c: any) => c.creative_id === creative.creative.id,
      )

      if (creative_index > -1) {
        const local_creative = this.local_value[creative_index].creative
        this.local_value[creative_index].creative = Object.assign(local_creative, creative.creative)
      }
      this.edit_creative = false
    } else {
      this.local_value.push(AssociatedCreative.toObject(clone(creative)))
    }

    this.local_creative_sidebar = false
  }

  public openCreativeSideBar() {
    this.local_modal = false
    this.local_creative_sidebar = true
  }

  public previewCreative(creative: any) {
    this.edit_creative = true
    this.temp_creative = creative
    let temp = JSON.parse(JSON.stringify(creative))
    this.openCreativeSideBar()
    this.loading_creative = true
    setTimeout(() => {
      this.creative_form.editCreative(Creative.toObject(temp))
      this.loading_creative = false
    }, 500)
  }
}
