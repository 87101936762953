
import ViewModel from '@/models/ViewModel'
import {
  Component, Prop, Ref, Vue, Watch,
} from 'vue-property-decorator'
import IconAction from '@/components/IconAction/IconAction.vue'
import FormInput from '@/components/FormInput/FormInput.vue'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import WebMessage from '@/models/WebMessage'

@Component({
  components: {
    FormInput,
    IconAction,
    SelectPicker,
  },
})
export default class CustomTargetting extends ViewModel {
  @Prop()
  value!: any

  public custom_targetting: any = ['']

  public custom_targetting_list: any = []

  @Watch('custom_targetting')
  public onTargettingChange(val: any) {
    if (val) {
      this.$emit('input', val)
    }
  }

  public mounted() {
    this.custom_targetting = this.value
  }

  public validateEmail(email: string) {
    const res = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return res.test(email.toLowerCase())
  }

  public addTag(newTag: string, index: number) {
    // console.log('add tag', newTag)
    if (this.validateEmail(newTag)) {
      const tag = {
        name: newTag,
        value: newTag,
      }
      this.custom_targetting_list.push(tag)
      this.custom_targetting[index] = newTag
    } else {
      WebMessage.error('Please enter a valid email.')
    }
  }

  public addTarget() {
    this.custom_targetting.push('')
  }

  public removeTarget(index: number) {
    this.custom_targetting.splice(index, 1)
  }

  public clearAll() {
    this.custom_targetting = ['']
  }
}
