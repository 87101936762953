
import ViewModel from '@/models/ViewModel'
import {
  Component, Ref, Vue, Watch,
} from 'vue-property-decorator'
import PageHeader from '@/components/PageHeader/PageHeader.vue'
import Widget from '@/components/Widget/Widget.vue'
import { FormWizard, TabContent } from 'vue-form-wizard'
import MediaPlanItem from '@/models/MediaPlanItem'
import Order from '@/models/Order'
import DatePicker from '@/components/DatePicker/DatePicker.vue'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import FooterNav from '@/components/FooterNav/FooterNav.vue'
import LineItem from '@/models/LineItem'
import WebMessage from '@/models/WebMessage'
import MediaPackage from '@/models/MediaPackage'
import MediaPlan from '@/models/MediaPlan'
import SelectOption from '@/models/interface/SelectOption'
import moment from 'moment'
import LineItemPicker from '@/components/LineItemPicker/LineItemPicker.vue'
import CreativeSimpleTable from '@/components/CreativeSimpleTable/CreativeSimpleTable.vue'
import CreativeModalCreator from '@/components/CreativeModalCreator/Index.vue'
import AssociatedCreative from '@/models/AssociatedCreative'
import LineItemForm from '../LineItem/components/LineItemForm.vue'
import OrderForm from '../Order/components/OrderForm.vue'

@Component({
  components: {
    PageHeader,
    Widget,
    FormWizard,
    TabContent,
    OrderForm,
    DatePicker,
    SelectPicker,
    LineItemForm,
    FooterNav,
    LineItemPicker,
    CreativeSimpleTable,
    CreativeModalCreator,
  },
})
export default class MediaPlanItemEdit extends ViewModel {
  @Ref() creative_modal!: CreativeModalCreator

  @Ref() nextBtn!: any

  @Ref() validator!: any

  @Ref() form_wizard!: any

  @Ref() line_item_form!: any

  public can_navigate: boolean = false

  public hide_footer: boolean = false

  public isEditing: boolean = false

  public loading: boolean = true

  public saving: boolean = false

  public busy: boolean = true

  public step: number = 1

  public modal_open: boolean = false

  public active_tab_index: number = 0

  public show_order_picker: boolean = false

  public order: Order = new Order()

  public order_line_item: LineItem = new LineItem()

  public media_plan_item: MediaPlanItem = new MediaPlanItem()

  public media_plan: MediaPlan = new MediaPlan()

  public media_package: MediaPackage = new MediaPackage()

  public available_orders: Order[] = []

  public selected_order_id: string | null = null

  public selected_order: Order = new Order()

  public selected_type: string = ''

  public first_tab_options: any = [
    {
      title: 'Use Existing  Media Plan Order',
      description: 'Order will use information from Media Plan',
      color: 'success',
      value: 'existing_media_plan',
      disabledText: 'No orders found for this media plan',
      enabledText: 'Add new line item to existing Media Plan order',
      tooltip: '',
      disabled: false,
    },
    // {
    //   title: 'Use Existing Media Plan Item Order',
    //   description: 'Order will use information from Media Plan item',
    //   color: 'success',
    //   value: 'existing_item',
    //   disabledText: 'No orders found for this media plan item',
    //   enabledText: 'Add new line item to existing Media Plan item order',
    //   tooltip: '',
    //   disabled: false,
    // },
    {
      title: 'Create New Order for the Media Plan',
      description: 'Order will use information from Media Plan',
      color: 'info',
      value: 'create_from_media_plan',
      enabledText: 'Create new order for the media plan',
      tooltip: '',
      disabled: false,
    },
    // {
    //   title: 'Create New Order for the Line Item',
    //   description: 'Order will use information from Media Plan item',
    //   color: 'info',
    //   value: 'create_from_item',
    //   enabledText: 'Create new order for the media plan item',
    //   tooltip: '',
    //   disabled: false,
    // },
  ]

  public selectable_orders: boolean = false

  public latest_line_items: any = []

  public selected_line_items: any = []

  public add_creative: boolean = false

  public creative_sidebar: boolean = false

  public temp_creatives: AssociatedCreative[] = []

  public get order_options() {
    return this.available_orders
      .filter((order: Order) => {
        if (this.selected_type === 'existing_media_plan') {
          return order.media_plan_id === this.media_plan.id && !order.media_plan_item_id
        }

        return (
          order.media_plan_id === this.media_plan.id
          && order.media_plan_item_id === this.media_plan_item.id
        )
      })
      .map((order: Order) => new SelectOption(order.name, order.id))
  }

  public get conditional_options() {
    return this.first_tab_options.map(o => {
      if (o.value === 'existing_media_plan') {
        let contains = this.available_orders.some(
          (order: any) => order.media_plan_id === this.media_plan.id && !order.media_plan_item_id,
        )

        if (!contains) {
          o.disabled = true
          o.tooltip = o.disabledText
        } else {
          o.tooltip = o.enabledText
        }

        return o
      }
      if (o.value === 'existing_item') {
        let contains = this.available_orders.some(
          (order: any) =>
            order.media_plan_id === this.media_plan.id
            && order.media_plan_item_id === this.media_plan_item.id,
        )
        if (!contains) {
          o.disabled = true
          o.tooltip = o.disabledText
        } else {
          o.disabled = false
          o.tooltip = o.enabledText
        }

        return o
      }
      return o
    })
  }

  public get lastStep() {
    return this.step === 4
  }

  @Watch('selected_order_id')
  public onOrderChange() {
    let o = this.available_orders.find((order: Order) => order.id === this.selected_order_id)

    if (o) {
      this.selected_order = o
      this.buildOrderAndLineItem().then(() => {
        this.form_wizard.nextTab()
        this.can_navigate = true
        this.show_order_picker = false
        this.selected_order_id = null
      })
    }
  }

  public mounted() {
    this.init()
  }

  public init() {
    return MediaPlanItem.find(this.$route.params.id).then(media_plan_item => {
      this.media_plan_item = media_plan_item
      if (this.media_plan_item.media_plan_id) {
        MediaPlan.find(this.media_plan_item.media_plan_id).then(media_plan => {
          this.media_plan = media_plan
          if (
            this.$route.query
            && this.$route.query.push_adserver
            && this.$route.query.push_adserver === 'upcoming_campagins'
          ) {
            let ids = {
              media_plan_item_id: this.media_plan_item.id,
              media_plan_id: this.media_plan.id,
            }
            Order.checkOrderItems(ids).then(response => {
              this.available_orders = response.orders
              this.busy = false
              this.loading = false
            })
          } else {
            this.busy = false
            this.loading = false
          }
        })
      }

      if (media_plan_item.media_package_id) {
        return MediaPackage.find(media_plan_item.media_package_id).then(
          (media_package: MediaPackage) => {
            this.media_package = media_package
          },
        )
      }
    })
  }

  public selectMode(option: any) {
    if (option.disabled) return
    this.show_order_picker = false
    this.selected_type = option.value
    if (this.selected_type.includes('existing')) {
      let elegible_orders = this.available_orders.filter(
        (order: any) => order.media_plan_id === this.media_plan.id && !order.media_plan_item_id,
      )
      if (elegible_orders.length === 1) {
        this.selected_order = elegible_orders[0]
        this.can_navigate = true

        this.buildOrderAndLineItem().then(() => {
          this.form_wizard.nextTab()
          this.can_navigate = true
        })
      } else {
        this.show_order_picker = true
      }
    } else {
      this.buildOrderAndLineItem().then(() => {
        this.form_wizard.nextTab()
        this.can_navigate = true
      })
    }
  }

  public buildOrderAndLineItem() {
    return new Promise(resolve => {
      if (this.selected_type.includes('existing')) {
        this.order = Order.toObject(this.selected_order)
      } else {
        let name = `${this.media_plan.name} | ${this.media_plan_item.name}`
        let source: MediaPlan | MediaPlanItem = this.media_plan_item

        if (this.selected_type === 'create_from_media_plan') {
          source = this.media_plan
          name = `${source.name}`
        }

        this.order = Order.toObject({
          agency_id: this.media_plan.agency_id,
          advertiser_id: this.media_plan.advertiser_id,
          station_id: this.media_plan.station_id,
          media_plan_id: this.media_plan.id,
          media_plan_item_id: source instanceof MediaPlanItem ? source.id : null,
          contracted_amount: source.impressions,
          trafficker_id: this.user.id,
          name,
          buffer_rule: 'percentage',
          buffer_percentage: 10,
          start_at: moment(source.start_at).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
          end_at: moment(source.end_at).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
          archived: false,
          metrics: {},
        })
      }

      // Line Item Section
      let time_restrictions: any = []
      let target_time = 'system'

      if (this.media_plan_item.media_plan?.isLinear) {
        const all_days = [
          'monday',
          'tuesday',
          'wednesday',
          'thursday',
          'friday',
          'saturday',
          'sunday',
        ]
        let optout_days = [...all_days]

        optout_days = optout_days.filter(day => !this.media_plan_item.metadata.days.includes(day))

        time_restrictions = [
          {
            id: this.randomUUID(),
            use_specific_dates: false,
            start_at: '',
            end_at: '',
            time_start_at: this.media_plan_item.metadata.flight_time.start_at,
            time_end_at: this.media_plan_item.metadata.flight_time.end_at,
            days: [...this.media_plan_item.metadata.days],
            type: 'include',
            available_weekdays: [],
            has_errors: false,
            _watcher: false,
          },
        ]
        target_time = 'user'

        if (optout_days.length > 0) {
          // Disabled as requested by ad ops
          // time_restrictions.push({
          //   id: this.randomUUID(),
          //   use_specific_dates: true,
          //   start_at: this.media_plan_item.start_at,
          //   end_at: this.media_plan_item.end_at,
          //   time_start_at: '00:00',
          //   time_end_at: '23:59',
          //   days: optout_days,
          //   type: 'exclude',
          //   available_weekdays: [],
          //   has_errors: false,
          //   _watcher: false,
          // })
        }
      }

      let order_line_item = Object.assign(this.order_line_item, {
        start_at: moment(this.media_plan_item.effective_start_at)
          .startOf('day')
          .format('YYYY-MM-DD HH:mm:ss'),
        end_at: moment(this.media_plan_item.effective_end_at)
          .endOf('day')
          .format('YYYY-MM-DD HH:mm:ss'),
        agency_id: this.media_plan_item.media_plan?.agency_id,
        advertiser_id: this.media_plan_item.media_plan?.advertiser_id,
        station_id: this.media_plan_item.media_plan?.station_id,
        media_plan_id: this.media_plan_item.media_plan?.id,
        media_plan_item_id: this.media_plan_item.id,
        contracted_amount: this.media_plan_item.impressions,
        name: this.media_plan_item.name,
        targetting: {
          target_time,
          active: !!this.media_plan_item.media_plan?.isLinear,
          time_restrictions,
          include_dmas: this.media_plan_item.metadata.targetting?.include.dmas || [],
          exclude_dmas: this.media_plan_item.metadata.targetting?.exclude.dmas || [],
          include_countries: this.media_plan_item.metadata.targetting?.include.countries || [],
          exclude_countries: this.media_plan_item.metadata.targetting?.exclude.countries || [],
          include_states: this.media_plan_item.metadata.targetting?.include.states || [],
          exclude_states: this.media_plan_item.metadata.targetting?.exclude.states || [],
          include_cities: this.media_plan_item.metadata.targetting?.include.cities || [],
          exclude_cities: this.media_plan_item.metadata.targetting?.exclude.cities || [],
          include_zips: this.media_plan_item.metadata.targetting?.include.zipcodes || [],
          exclude_zips: this.media_plan_item.metadata.targetting?.exclude.zipcodes || [],
          include_devices: this.media_plan_item.metadata.targetting?.include.devices || [],
          exclude_devices: this.media_plan_item.metadata.targetting?.exclude.devices || [],
          include_ip: this.media_plan_item.metadata.targetting?.include.ip || [],
          exclude_ip: this.media_plan_item.metadata.targetting?.exclude.ip || [],
          include_key_values: this.media_plan_item.metadata.targetting?.include.key_values || [],
          exclude_key_values: this.media_plan_item.metadata.targetting?.exclude.key_values || [],
          include_adunits: this.media_package.adunit_ids || [],
          exclude_adunits: this.media_package.exclude_adunit_ids || [],
        },
        distribution_goals: this.media_package.distribution_goals || [],
      })

      this.order_line_item.order = this.order
      if (
        this.$route.query
        && this.$route.query.hasOwnProperty('push_adserver')
        && this.$route.query.push_adserver === 'upcoming_campagins'
      ) {
        order_line_item.buffer_percentage = 1
        order_line_item.buffer_rule = 'percentage'
      }

      Vue.set(this, 'order_line_item', order_line_item)

      resolve(true)
    })
  }

  public updateProgress(prevIndex: number, nextIndex: number) {
    if (nextIndex >= 0) {
      Vue.set(this, 'step', nextIndex + 1)
    }
  }

  public validateStep(index: any) {
    return true
  }

  public save(payload: any) {
    MediaPlanItem.pushToAdServer(payload)
      .then(response => {
        if (response.status === 200) {
          this.saving = false
          WebMessage.success('Order and Line Item Created')
          this.$router.push({
            name: 'order-overview',
            params: { id: response.data.result.order.id },
          })
        }
      })
      .catch(() => {
        this.saving = false
        this.loading = false
        this.hide_footer = false
      })
  }

  public async onSubmit() {
    this.saving = true

    if (!this.order_line_item.targetting.active) {
      this.order_line_item.targetting.time_restrictions = []
    }

    let payload = {
      order: this.order.apiData,
      order_line_item: this.order_line_item.apiData,
      associated_creatives: this.temp_creatives.map(creative => {
        let item = creative
        item.id = null
        return item
      }),
    }
    if (!this.temp_creatives || !this.temp_creatives.length) {
      const response = await WebMessage.confirm(
        'Are you sure you want to save without creatives?',
        'No Creatives',
        { okTitle: 'Save' },
      )

      if (!response) {
        this.saving = false
        this.loading = false
        this.hide_footer = false
        return
      }
    }

    this.order_line_item.order = this.order

    this.save(payload)

    // Disable order validation
    // this.order_line_item.checkOrderInformation((issues: any[]) => {
    //   if (issues.length) {
    //     let msg = issues.reduce((acc, issue) => `${acc}* ${issue.message}`, '')
    //     this.hide_footer = true
    //     WebMessage.doubleConfirm(
    //       `${msg} Are you sure you want to continue?`,
    //       'Line Item issues',
    //       "Yes, understant that the line item will be limited by the order settings and I'll update the order if necessary.",
    //       { okTitle: 'Save' },
    //     ).then(result => {
    //       if (result) {
    //         this.save(payload)
    //       } else {
    //         this.saving = false
    //         this.loading = false
    //         this.hide_footer = false
    //       }
    //     })
    //     //
    //   } else {
    //     this.save(payload)
    //   }
    // })
  }

  public wizardUpdateLocal() {
    this.active_tab_index = this.form_wizard.activeTabIndex
  }

  public nextItem() {
    this.form_wizard.nextTab()

    this.wizardUpdateLocal()
  }

  public prevItem() {
    this.form_wizard.prevTab()
    this.wizardUpdateLocal()
  }

  public cancel() {
    this.$router.push({ name: 'mediaplan-item-home' })
  }
}
