
import ViewModel from '@/models/ViewModel'
import {
  Component, Prop, Ref, Vue, Watch,
} from 'vue-property-decorator'
import LabelForm from './LabelForm.vue'

@Component({
  components: {
    LabelForm,
  },
})
export default class LabelSideBar extends ViewModel {
  @Ref() label_form!: any

  public new_id: string | null = null

  public local_show: boolean = false

  @Prop()
  public show!: boolean

  @Watch('show')
  public onShowChange(val: boolean) {
    this.local_show = val
  }

  @Watch('local_show')
  public onShowSidebar(val: boolean) {
    this.$emit('update:show', val)
  }

  @Watch('new_id')
  public onLocalId(val: boolean) {
    if (val) {
      this.$emit('update:new_id', val)
      this.$emit('update:show', false)
    }
  }
}
